import { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { apiUrl, fillerPalette, maxResolution} from "../constants";
import axios from 'axios';
import chroma from "chroma-js";
import RgbQuant from 'rgbquant';
import "../css/Open.css";
import { storeDocumentData, canvasToBlob } from "../utils/document";
import { onlyIntInput} from "../utils/basics";
import previewBGFile from "../assets/images/preview-background.png";
import { rampPalette } from "../utils/drawing";

function Open({ palettes, onCreate, newTab, group}) {
    const navigate = useNavigate();

    const canvas = useRef(null);
    const input = useRef(null);
    const paletteInput = useRef(null);
    const previewBG = useRef(new Image());
    const currentPalette = useRef(0);

    const [image, setImage] = useState(null);
    const [name, setName] = useState('Untitled');
    const [aspectRatio, setAspectRatio] = useState(1);
    const [width, setWidth] = useState(64);
    const [height, setHeight] = useState(64);
    const [maxWidth, setMaxWidth] = useState(maxResolution);
    const [maxHeight, setMaxHeight] = useState(maxResolution);
    const [canvasStyle, setCanvasStyle] = useState({ display: "none"});
    const [imagePalette, setImagePalette] = useState(null);
    const [uploadedPalette, setUploadPalette] = useState(null);
    const [paletteArray, setPaletteArray] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoadig, setIsLoading] = useState(true);
    const [paletteSelection, setPaletteSelection] = useState(0);

    useEffect(() => {        
        previewBG.current.onload = async () => {
            setIsLoading(false);
        };
        previewBG.current.src = previewBGFile;
    }, []);
    
    // Helper function to store document ID in local storage
    const handleSubmit = async (e) => {
        e.preventDefault();

        if(currentPalette.current === -1 && !uploadedPalette) return setErrorMessage("No palette Loaded.");
        if(!name) return setErrorMessage("Document name is required.");
        if(name.length > 64) return setErrorMessage("Document name must be 64 or less characters.");
        if(!/^[A-Za-z0-9_ -]+$/.test(name)) return setErrorMessage("Document name contains invalid characters.");

        const groupId = group ? group._id : null;
          
        const palette = [];
        for(const index of paletteArray){
            let color;
            if(currentPalette.current === -1){
                color = index < 32 ? imagePalette[index] : uploadedPalette[index-32];
            } else {
                color = index < 32 ? imagePalette[index] : palettes[currentPalette.current].palette[index-32];
            }
            palette.push(color);
        }
        for(let i = palette.length; i < 32; i++){
            palette.push('');
        }

        const payload = {
            name: name.trim(),
            width,
            height,
            palette,
            group: groupId,
        };

        try {
            const response = await axios.post(apiUrl + "/api/openImage", payload);
    
            if (response.status === 201) {
                const { token, docId, layerId} = response.data;
    
                // Store the document ID in local storage for all users
                storeDocumentData(docId, token);

                //save layer
                const imgData = new FormData();
                const blob = await canvasToBlob(canvas.current);
                imgData.append(layerId, blob, `${layerId}.png`);

                // Calculate the scaling factors for width and height
                const widthScale = 1200 / width;
                const heightScale = 627 / height;
                // Choose the minimum scaling factor to ensure the entire content fits within the target dimensions
                const scale = Math.min(widthScale, heightScale);
                //get canvas and ctx for outpt
                const previewCanvas = document.createElement("canvas");
                previewCanvas.width = width * scale;
                previewCanvas.height = height * scale;
                const ctx = previewCanvas.getContext("2d");
                ctx.imageSmoothingEnabled = false;
                //draw alpha
                ctx.drawImage(previewBG.current,0,0);
                //draw opened image
                ctx.drawImage(canvas.current, 0, 0, width,height, 0, 0,width * scale, height * scale);

                const CanvasBlob = await canvasToBlob(previewCanvas);
                imgData.append("preview", CanvasBlob, "preview.png")

                // Append token to the FormData
                imgData.append('token', token);

                await axios.post(`${apiUrl}/api/saveLayers/${docId}`, imgData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                
                onCreate();
    
                // Redirect to the document editor page
                if (newTab) {
                    return window.open(`/editor/${docId}`, '_blank', 'noopener,noreferrer');
                } else {
                    return navigate(`/editor/${docId}`);
                }
            }
        } catch (error) {   
            if (error.response && error.response.data && error.response.data.message) {
                return setErrorMessage(error.response.data.message);
            } else {
                console.error(error);
                return setErrorMessage("Error creating document.");
            }
        }
    };

    const handleNameChange = (e) => {
        setName(e.target.value.substring(0, 64));
    };

    function handleImageUpload(e) {
        setImage(null);
        setIsLoading(true);
        setErrorMessage(null);
        setCanvasStyle({display: "none"});
        currentPalette.current = 0;
        setPaletteSelection(0);

        //set name
        const filename = e.target.files[0].name;
        const extension = filename.split('.').pop();
        if(extension !== "jpg" && extension !== "jpeg" && extension !== "png" && extension !== "gif" && extension !== "webp"){
            setIsLoading(false);
            return setErrorMessage("Image must be a JPG, PNG, GIF, or WEBP.");
        }
        let docName = filename.substr(0, filename.lastIndexOf('.')) || filename;
        docName = docName.replace(/[^a-zA-Z0-9 _-]/g, '').trim();
        docName = docName.slice(0, 32);
        setName(docName);

        //upload file
        var img = new Image();
        img.onload = draw;
        img.onerror = failed;
        img.src = URL.createObjectURL(e.target.files[0]);
    };

    const handleImageBrowse = (e) => {
        e.preventDefault();
        input.current.click();
    };

    function draw(){
        setImage(this);

        //constrain image to max resolution
        const tempAspectRatio = this.width/this.height;
        let tempWidth = this.width;
        let tempHeight = this.height;
        if(tempAspectRatio > 1){
            if(tempWidth > maxResolution){
                tempWidth = maxResolution;
                tempHeight = Math.round(maxResolution / tempAspectRatio);
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(tempWidth);
                setMaxHeight(tempHeight);
            } else {
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(maxResolution);
                setMaxHeight(Math.round(maxResolution / tempAspectRatio));
            }
        } else{
            if(tempHeight > maxResolution){
                tempWidth = Math.round(maxResolution * tempAspectRatio);
                tempHeight = maxResolution;
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(tempWidth);
                setMaxHeight(tempHeight);
            } else {
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(Math.round(maxResolution * tempAspectRatio));
                setMaxHeight(maxResolution);
            }
        }
        setAspectRatio(tempAspectRatio);

        //get and draw canvas
        canvas.current.width = tempWidth > 0 ? tempWidth : 1;
        canvas.current.height = tempHeight > 0 ? tempHeight : 1;
        const ctx = canvas.current.getContext("2d", { willReadFrequently: true });
        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(this, 0, 0, canvas.current.width, canvas.current.height);

        //set transparency to full transparent
        const imageData = ctx.getImageData( 0, 0, canvas.current.width, canvas.current.height);
        let hasColor;
        for (let j = 0; j < imageData.data.length; j += 4) {
            if(imageData.data[j + 3] > 0) {
                imageData.data[j + 3] = 255;
                hasColor = true;
            }
        }
        ctx.putImageData(imageData, 0, 0);

        if(!hasColor){
            setImage(null);
            setIsLoading(false);
            return setErrorMessage("Image must have opaque pixels.");
        }

        //get sampled palette
        const q = new RgbQuant({colors: 32});
        q.sample(canvas.current);
        const pal = q.palette(true, true);

        const newPalette = [];
        for(const color of pal){
            newPalette.push(chroma(color).hex());
        }

        const rampedPalette = rampPalette(newPalette);

        setImagePalette(rampedPalette);

        const tempPalette = [];
        for(let i = 0; i < newPalette.length; i ++){
            tempPalette.push(i);      
        }
        // for(let j = 32; j < 64; j++){
        //     if(tempPalette.length < 32) tempPalette.push(j);    
        // }
        setPaletteArray(tempPalette);

        //quantize image    
        const u8  = q.reduce(canvas.current, true);
        const clamped = new Uint8ClampedArray(u8);
        const imgData2 = new ImageData(clamped, canvas.current.width, canvas.current.height);
        ctx.putImageData(imgData2,0,0);

        setIsLoading(false);
        setCanvasStyle({});

        return setErrorMessage(null);
    }

    const quantizeImage = useCallback((newArray) => {
        //get and draw canvas
        const ctx = canvas.current.getContext("2d", { willReadFrequently: true });
        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(image, 0, 0, canvas.current.width, canvas.current.height);
        
        //set transparency to full transparent
        const imageData = ctx.getImageData( 0, 0, canvas.current.width, canvas.current.height);
        let hasColor;
        for (let j = 0; j < imageData.data.length; j += 4) {
            if(imageData.data[j + 3] > 0) {
                imageData.data[j + 3] = 255;
                hasColor = true;
            }
        }
        ctx.putImageData(imageData, 0, 0);

        if(!hasColor){
            setImage(null);
            setIsLoading(false);
            return setErrorMessage("Image must have opaque pixels.");
        }

        //quantize image  
        const rgbPalette = [];
        for(const index of newArray){
            let color;
            if(currentPalette.current === -1){
                color = index < 32 ? imagePalette[index] : uploadedPalette[index-32];
            } else {
                color = index < 32 ? imagePalette[index] : palettes[currentPalette.current].palette[index-32];
            }
            rgbPalette.push(chroma(color).rgb());
        }
        const q = new RgbQuant({colors: rgbPalette.length, palette: rgbPalette});
        q.sample(canvas.current);
        const u8  = q.reduce(canvas.current, true);
        const clamped = new Uint8ClampedArray(u8);
        const imgData2 = new ImageData(clamped, canvas.current.width, canvas.current.height);
        ctx.putImageData(imgData2,0,0);

        return setErrorMessage(null);
    }, [palettes, paletteArray, imagePalette, uploadedPalette]);

    const handleSwatchClick = useCallback((index) => {
        setErrorMessage(null);
        let newArray = [...paletteArray];
        if (newArray.includes(index)) {
            newArray = newArray.filter(i => i !== index);
            if(newArray.length === 0) return;
        } else if (newArray.length < 32) {
            newArray.push(index);
        }
        setPaletteArray(newArray);

        quantizeImage(newArray);
    }, [paletteArray, quantizeImage]);

    const selectAllOld = useCallback(() => {
        if(imagePalette && imagePalette.length){
            const newArray = [];
            for(let i = 0; i < imagePalette.length; i++){
                if(newArray.length < 32) newArray.push(i);    
            }
            if(currentPalette.current === -1){
                if(uploadedPalette){
                    for(let j = 32; j < 32 + uploadedPalette.length; j ++){
                        if(paletteArray.includes(j) && newArray.length < 32) newArray.push(j);      
                    }
                }
            } else {
                for(let j = 32; j < 32 + palettes[currentPalette.current].palette.length; j ++){
                    if(paletteArray.includes(j) && newArray.length < 32) newArray.push(j);      
                }
            }
            setPaletteArray([...newArray]);
            quantizeImage(newArray);
        }
    }, [palettes, quantizeImage]);;

    const selectAllNew = useCallback(() => {
        if(imagePalette && imagePalette.length){
            const newArray = [];
            if(currentPalette.current === -1){
                if(uploadedPalette){
                    for(let j = 32; j < 32 + uploadedPalette.length; j ++){
                        if(newArray.length < 32) newArray.push(j);      
                    }
                }
            } else {
                for(let j = 32; j < 32 + palettes[currentPalette.current].palette.length; j ++){
                    if(newArray.length < 32) newArray.push(j);      
                }
            }
            for(let i = 0; i < imagePalette.length; i++){
                if(paletteArray.includes(i) && newArray.length < 32) newArray.push(i);    
            }
            setPaletteArray([...newArray]);
            quantizeImage(newArray);
        }
    }, [palettes, paletteArray, quantizeImage]);

    const deselectAllOld = useCallback(() => {
        let newArray = [...paletteArray];
        newArray = newArray.filter(i => i > 31);
        if(newArray.length === 0) newArray = [32];
        setPaletteArray(newArray);
        quantizeImage(newArray);
    }, [paletteArray, quantizeImage]);

    const deselectAllNew = useCallback(() => {
        let newArray = [...paletteArray];
        newArray = newArray.filter(i => i < 32);
        if(newArray.length === 0) newArray = [0];
        setPaletteArray(newArray);
        quantizeImage(newArray);
    }, [paletteArray, quantizeImage]);

    const fillSelection = useCallback(() => {
        const newArray = [];
        for(let i = 0; i < 32; i++){
            if(paletteArray.includes(i)) newArray.push(i);    
        }
        if(currentPalette.current === -1){
            if(uploadedPalette){
                for(let j = 32; j < 32 + uploadedPalette.length; j ++){
                    if(newArray.length < 32) newArray.push(j);      
                }
            }
        } else {
            for(let j = 32; j < 32 + palettes[currentPalette.current].palette.length; j ++){
                if(newArray.length < 32) newArray.push(j);      
            }
        }
        setPaletteArray(newArray);
        quantizeImage(newArray);
    }, [palettes, paletteArray, quantizeImage]);

    const handlePaletteChange = (e) => {
        if(e.target.value === "file"){
            currentPalette.current = -1;
            setPaletteSelection(-1);
        } else {
            currentPalette.current = e.target.value;
            setPaletteSelection(e.target.value);
            fillSelection();
            setUploadPalette(null);
        }
    };
    
    const handlePaletteBrowse = (e) => {
        e.preventDefault();
        paletteInput.current.click();
    };

    function handlePaletteUpload(e) {
        const file = e.target.files[0];
        if (!file) {
            return setErrorMessage("No file uploaded.");;
        }

        setUploadPalette(null);
        setErrorMessage(null);

        const filename = file.name.toLowerCase();
        const extension = filename.split('.').pop();
        if(extension === "jpg" || extension === "jpeg" || extension === "png" || extension === "gif" || extension === "webp"){
            //upload file
            var img = new Image();
            img.onload = reduce;
            img.onerror = failed;
            img.src = URL.createObjectURL(file);
        } else if(extension === "txt"){
            const reader = new FileReader();
            reader.onload = function(e) {
                const content = e.target.result;
                const lines = content.split('\n');
                const newPalette = [];
            
                lines.forEach(line => {
                    line = line.trim();
                    if (line && !line.startsWith(';')) {
                        // Remove the leading FF if present
                        if (line.startsWith('FF')) {
                            line = line.slice(2, 8);
                        } else if(line.startsWith('#')){
                            line = line.slice(1, 7);
                        }
                        if(line !== '' && newPalette.length < 32) newPalette.push('#' + line);
                    }
                });
            
                setUploadPalette(newPalette);
            };
            reader.readAsText(file);
        } else if (extension === "hex"){
            const reader = new FileReader();
            reader.onload = function(e) {
                const content = e.target.result;
                const lines = content.split('\n');
                const newPalette = [];
            
                lines.forEach(line => {
                    line = line.trim();
                    line = line.slice(0, 6);
                    if(line !== '' && newPalette.length < 32) newPalette.push('#' + line);
                });
            
                setUploadPalette(newPalette);
            };
            reader.readAsText(file);
        } else {
            return setErrorMessage("File must be a JPG, PNG, GIF, WEBP, TXT, or HEX.");
        }
    };

    function reduce(){
        const canvas = document.createElement("canvas");
        canvas.width = this.width;
        canvas.height = this.height;
        const ctx = canvas.getContext("2d");
        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(this, 0, 0, canvas.width, canvas.height);

        //get sampled palette
        const q = new RgbQuant({colors: 32});
        q.sample(canvas);
        const pal = q.palette(true, true);
        
        const newPalette = []

        for(const color of pal){
            newPalette.push(chroma(color).hex());
        }

        const rampedPalette = rampPalette(newPalette);

        setUploadPalette(rampedPalette);
    }

    function failed(){
        return setErrorMessage("Error loading image.");
    }

    useEffect(() => {
        if(uploadedPalette) fillSelection();
    }, [uploadedPalette]);

    const handleWidthChange = useCallback((e) => {
        //constrain image to max resolution
        let tempWidth = e.target.value;
        let tempHeight = Math.round(tempWidth / aspectRatio);
        if(aspectRatio > 1){
            if(tempWidth > maxResolution){
                tempWidth = maxResolution;
                tempHeight = Math.round(maxResolution / aspectRatio);
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(tempWidth);
                setMaxHeight(tempHeight);
            } else {
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(maxResolution);
                setMaxHeight(Math.round(maxResolution / aspectRatio));
            }
        } else{
            if(tempHeight > maxResolution){
                tempWidth = Math.round(maxResolution * aspectRatio);
                tempHeight = maxResolution;
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(tempWidth);
                setMaxHeight(tempHeight);
            } else {
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(Math.round(maxResolution * aspectRatio));
                setMaxHeight(maxResolution);
            }
        }

        canvas.current.width = tempWidth;
        canvas.current.height = tempHeight;

        quantizeImage(paletteArray);
    }, [aspectRatio, palettes, imagePalette, paletteArray]);

    const handleHeightChange = useCallback((e) => {
        //constrain image to max resolution
        let tempHeight = e.target.value;
        let tempWidth = Math.round(tempHeight * aspectRatio);
        if(aspectRatio > 1){
            if(tempWidth > maxResolution){
                tempWidth = maxResolution;
                tempHeight = Math.round(maxResolution / aspectRatio);
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(tempWidth);
                setMaxHeight(tempHeight);
            } else {
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(maxResolution);
                setMaxHeight(Math.round(maxResolution / aspectRatio));
            }
        } else{
            if(tempHeight > maxResolution){
                tempWidth = Math.round(maxResolution * aspectRatio);
                tempHeight = maxResolution;
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(tempWidth);
                setMaxHeight(tempHeight);
            } else {
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(Math.round(maxResolution * aspectRatio));
                setMaxHeight(maxResolution);
            }
        }

        canvas.current.width = tempWidth;
        canvas.current.height = tempHeight;
        
        quantizeImage(paletteArray);
    }, [aspectRatio, palettes, imagePalette, paletteArray]);

    const swatchIncluded = (index) => {
        const included = paletteArray.includes(index) ? "open-swatch included" : "open-swatch"
        return included;
    };

    return (
        <div className="open-wrapper">
            <h2 className="open-heading">Open Image</h2>
            {isLoadig && 
                <>
                    <div>Quantizing image. Please wait.</div>
                    <div className="open-loading-wrapper">
                        <span className="icon-spinner spinner"></span>
                    </div>
                </>
            }
            {image && !isLoadig &&
                <>
                    <label className="open-label" htmlFor="name" autoComplete="off" maxlength="64">Document Name:</label>
                    <input id="name" className="open-input-name" value={name} onChange={handleNameChange} accept=".jpg,.jpeg,.png,.gif,.webp"/>
                    <label className="open-label" htmlFor="width">Width:</label>
                    <div className="open-input-wrapper">
                        <input
                            id="width"
                            type="number"
                            className="open-size"
                            value={width}
                            onKeyDown={onlyIntInput}
                            onChange={handleWidthChange}
                            max={maxWidth}
                        /> Max: {maxWidth}px
                    </div>
                    <label className="open-label" htmlFor="height">Height:</label>
                    <div className="open-input-wrapper">
                        <input
                            id="height"
                            type="number"
                            className="open-size"
                            value={height}
                            onKeyDown={onlyIntInput}
                            onChange={handleHeightChange}
                            max={maxHeight}
                        /> Max: {maxHeight}px
                    </div>

                    <label className="open-label" htmlFor="name">Sampled Colors:</label>
                    <div className="open-swatches">
                        {imagePalette && imagePalette.map((swatch, index) => (
                            <div
                                className={swatchIncluded(index)}
                                style={{ backgroundColor: swatch }}
                                onClick={() => handleSwatchClick(index)}
                            >
                            </div>
                        ))}
                    </div>
                    <div className="open-right">
                        <button className="open-button" onClick={deselectAllOld}>Deselect All</button>
                        <button className="open-button" onClick={selectAllOld}>Select All</button>
                    </div>
                </>
            }
            
            <canvas ref={canvas} className="open-canvas" style={canvasStyle}></canvas>

            {image && !isLoadig ?
                <>
                    <select
                        name="palettes"
                        id="palettes"
                        className="open-select"
                        onChange={handlePaletteChange}
                        value={paletteSelection}
                    >
                        <option value="file">Load From File</option>
                        {palettes && palettes.map((paletteItem, index) => (
                            <option key={index} value={index}>{paletteItem.name}</option>
                        ))}
                    </select>
                    {paletteSelection !== -1 &&
                        <>
                            <div className="open-swatches">
                                {palettes && palettes[paletteSelection].palette.map((swatch, index) => (
                                    <div
                                        className={swatchIncluded(32 + index)}
                                        style={{ backgroundColor: swatch }}
                                        onClick={() => handleSwatchClick(32 + index)}
                                    >
                                    </div>
                                ))}
                            </div>
                            <div className="open-right">
                                <button className="open-button" onClick={deselectAllNew}>Deselect All</button>
                                <button className="open-button" onClick={selectAllNew}>Select All</button>
                            </div>
                        </>
                    }
                    {paletteSelection === -1 && uploadedPalette &&
                        <>
                            <div className="open-swatches">
                                {uploadedPalette && uploadedPalette.map((swatch, index) => (
                                    <div
                                        className={swatchIncluded(32 + index)}
                                        style={{ backgroundColor: swatch }}
                                        onClick={() => handleSwatchClick(32 + index)}
                                    >
                                    </div>
                                ))}
                            </div>
                            <div className="open-right">
                                <button className="open-button" onClick={deselectAllNew}>Deselect All</button>
                                <button className="open-button" onClick={selectAllNew}>Select All</button>
                            </div>
                            <div className="open-right">
                                    <input type="file" onChange={handlePaletteUpload} ref={paletteInput} style={{display: 'none'}} accept=".jpg,.jpeg,.png,.gif,.webp,.txt,.hex" />
                                    <button className="new-document-button" onClick={handlePaletteBrowse}>Browse</button>
                            </div>
                        </>
                    }
                    {paletteSelection === -1 && uploadedPalette === null &&
                        <div className="open-right">
                                <input type="file" onChange={handlePaletteUpload} ref={paletteInput} style={{display: 'none'}} accept=".jpg,.jpeg,.png,.gif,.webp,.txt,.hex" />
                                <button className="new-document-button" onClick={handlePaletteBrowse}>Browse</button>
                        </div>
                    }

                    <label className="open-label">Swatches Selected: {paletteArray.length}/32</label>
                </>
                :
                <>
                    <input type="file" onChange={handleImageUpload} ref={input} style={{display: 'none'}} />
                    <button className="open-browse" onClick={() => input.current.click()}>Browse for an image</button>
                </>
            }
            {errorMessage && <div className="open-error">{errorMessage}</div>}
            {image && !isLoadig && <>
                <div className="open-bottom">
                    <input type="file" onChange={handleImageUpload} ref={input} style={{display: 'none'}} />
                    <input type="submit" value="Browse" className="open-submit" onClick={handleImageBrowse}/>
                    <input type="submit" value="Open" className="open-submit" onClick={handleSubmit}/>
                </div>
            </>}
        </div>
    );
}

export default Open;
