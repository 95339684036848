import React, { useState, useEffect, useRef, useCallback } from "react";
import "../css/Palette.css";
import { useMainContext } from "../contexts/MainContext";
import blankSwatch from "../assets/images/plus.png";
import { distance } from "../utils/drawing";
import { metaKey } from "../constants";

function Palette() {
  const {
    palette,
    selectedSwatch,
    setSelectedSwatch,
    setColorModal,
    reorderSwatches,
    swatchSelect,
    swatchSelectIn,
    swatchSelectAdd,
    swatchSelectRemove,
    shiftKey,
    altKey,
    altButton,
    setAltButton,
    shiftButton,
    setShiftButton,
    metaButton,
    setMetaButton,
  } = useMainContext();

  const [draggingSwatch, setDraggingSwatch] = useState(null);
  const [clickSwatch, setClickSwatch] = useState(null);
  const [preventClick, setPreventClick] = useState(false);
  const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });
  const [moveLeft, setMoveLeft] = useState(null);
  const [svgWidth, setSvgWidth] = useState(null);
  const [svgHeight, setSvgHeight] = useState(null);
  const [cursorPath, setCursorPath] = useState(null);

  const floatingSwatch = useRef(null);
  const alphaRef = useRef(null);
  const scrolling = useRef(false);
  const swatches = useRef([]);
  swatches.current = palette.map((_, i) => swatches.current[i] ?? React.createRef());
  const touches = useRef([]);
  const paletteWrapperRef = useRef(null);
  const paletteWrapperInnerRef = useRef(null);
  const scrollFrameRef = useRef(null);
  const shiftRef = useRef(false);
  const altRef = useRef(false);
  const metaRef = useRef(false);
  const corsurCoordinate = useRef(null);
  const startPosition = useRef(null);
  const dragInitTimer = useRef(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setSvgWidth(entry.contentRect.width);
        setSvgHeight(entry.contentRect.height);
      }
    });

    // observe one or more elements
    if (paletteWrapperRef.current) {
      resizeObserver.observe(paletteWrapperRef.current);
    }

    return () => {
      // Cleanup
      if (paletteWrapperRef.current) {
        resizeObserver.unobserve(paletteWrapperRef.current);
        resizeObserver.disconnect();
      }
    };
  }, []);

  const alphaClass = () => {
    return parseInt(selectedSwatch) === 32 ? "swatch alpha selected" : "swatch alpha";
  };

  const handleSwatchChange = (e, swatch) => {
    if(preventClick) return setPreventClick(false);
    
    if (draggingSwatch || scrolling.current) return;
    clearTimeout(dragInitTimer.current);
    if(e.ctrlKey || e.metaKey || metaButton){
      if(swatch !== 32 && palette[swatch] === '') return;
      if(shiftKey || shiftButton){
        if(altKey || altButton){
          swatchSelectIn(swatch);
        } else {
          swatchSelectAdd(swatch);
        }
      } else if(altKey || altButton){
        swatchSelectRemove(swatch);
      } else {
        swatchSelect(swatch);
      }
    } else {
      if (swatch !== 32){
        if(palette[swatch] === '' || swatch === selectedSwatch) {
          setColorModal(swatch);
        } else {
          setSelectedSwatch(swatch);
        }
      } else {
        setSelectedSwatch(swatch);
      }
    }
  };

  const handleSwatchPointerDown = (e, color, swatch) => {
    e.preventDefault();
    startPosition.current = { x: e.clientX, y: e.clientY };
    setDragPosition({ x: e.clientX, y: e.clientY });
    if(e.pointerType === "touch"){
      dragInitTimer.current = setTimeout(() => {
        if(paletteWrapperInnerRef.current) paletteWrapperInnerRef.current.style.overflowY = 'hidden'
        setMoveLeft(swatch);
        setDraggingSwatch({ color, swatch });
      }, 500);
    } else {
      setClickSwatch({ color, swatch })
    }
  };

  const handleAutoScroll = () => {
    const paletteWrapper = paletteWrapperInnerRef.current;
    if (!paletteWrapper) return;

    const { top, bottom } = paletteWrapper.getBoundingClientRect();
    const scrollSpeed = window.screen.height / 192;
    const threshold = 48;

    if (dragPosition.y < top + threshold) {
      paletteWrapper.scrollTop -= scrollSpeed;
    } else if (dragPosition.y > bottom - threshold) {
      paletteWrapper.scrollTop += scrollSpeed;
    }
    scrollFrameRef.current = requestAnimationFrame(handleAutoScroll);
  };

  useEffect(() => {
    const handleTouchMove = (e) => {
      const touch = e.touches[0];
      setDragPosition({ x: touch.clientX, y: touch.clientY });

      if (draggingSwatch !== null) {
        // Update the position of the floating layer

        setMoveLeft(0);

        palette.forEach((_, index) => {
          const swatchRect = swatches.current[index].getBoundingClientRect();
          if (
            index !== draggingSwatch.swatch &&
            touch.clientY > swatchRect.top - 8 &&
            touch.clientY < swatchRect.bottom + 8 &&
            touch.clientX > swatchRect.left - 64
          ) {
            if(touch.clientX < swatchRect.left + 32){
              setMoveLeft(index);
            } else {
              setMoveLeft(index+1);
            }
          }
        });

        const alphaRect = alphaRef.current.getBoundingClientRect();
        if (
          touch.clientY > alphaRect.top - 8 &&
          touch.clientY < alphaRect.bottom + 8 &&
          touch.clientX > alphaRect.left - 64 &&
          touch.clientX < alphaRect.right
        ) {
          setMoveLeft(32);
        }

        // Handle first slot
        if (
          draggingSwatch.swatch !== 0 &&
          touch.clientY < swatches.current[0].getBoundingClientRect().top
        ) {
          setMoveLeft(0);
        }

        // Handle last slot
        const lastIndex = palette.length - 1;
        if (
          draggingSwatch.swatch !== lastIndex &&
          touch.clientY > swatches.current[lastIndex].getBoundingClientRect().bottom
        ) {
          setMoveLeft(lastIndex + 1);
        }

        // Start auto-scrolling
        if (!scrollFrameRef.current) {
          scrollFrameRef.current = requestAnimationFrame(handleAutoScroll);
        }
      } else {
        if(startPosition.current && distance(startPosition.current, { x: touch.clientX, y: touch.clientY }) > 5){
          clearTimeout(dragInitTimer.current);
          if(paletteWrapperInnerRef.current) paletteWrapperInnerRef.current.style.overflowY = 'auto';
        }
        if (scrollFrameRef.current) {
          cancelAnimationFrame(scrollFrameRef.current);
          scrollFrameRef.current = null;
        }
      }
    };

    const handlePointerMove = (e) => {
      if(e.pointerType === 'touch') return;

      altRef.current = altKey || altButton ?  true : false;
      shiftRef.current = shiftKey || shiftButton ? true : false;
      metaRef.current = e.ctrlKey || e.metaKey || metaButton ? true : false;
      
      if(!corsurCoordinate.current){
        setCursorPath(null);
      } else {const offset = 8;
        const lineLength = 8;
        const x = corsurCoordinate.current.x;
        const y = corsurCoordinate.current.y;
  
        if(metaRef.current){
          if(shiftRef.current){
            if(altRef.current){
              setCursorPath((
                <>
                  <line
                    key="plus-diag1-white"
                    x1={x + lineLength + offset}
                    x2={x + lineLength * 2 + offset}
                    y1={y + lineLength * 2 + offset}
                    y2={y + lineLength + offset}
                    stroke="white"
                    strokeWidth={4 / window.devicePixelRatio}
                    vectorEffect="non-scaling-stroke" 
                    strokeLinecap="round"
                  />
                  <line
                    key="plus-diag2-white"
                    x1={x + lineLength + offset}
                    x2={x + lineLength * 2 + offset}
                    y1={y + lineLength + offset}
                    y2={y + lineLength * 2 + offset}
                    stroke="white"
                    strokeWidth={4 / window.devicePixelRatio}
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                  />
                  <line
                    key="plus-diag1-black"
                    x1={x + lineLength + offset}
                    x2={x + lineLength * 2 + offset}
                    y1={y + lineLength * 2 + offset}
                    y2={y + lineLength + offset}
                    stroke="black"
                    strokeWidth={2 / window.devicePixelRatio}
                    vectorEffect="non-scaling-stroke"
                  />
                  <line
                    key="plus-diag2-black"
                    x1={x + lineLength + offset}
                    x2={x + lineLength * 2 + offset}
                    y1={y + lineLength + offset}
                    y2={y + lineLength * 2 + offset}
                    stroke="black"
                    strokeWidth={2 / window.devicePixelRatio}
                    vectorEffect="non-scaling-stroke"
                  />
                </>
              ));
            } else{
              setCursorPath((
                <>
                  <line
                    key="plus-horizontal-white"
                    x1={x + lineLength + offset}
                    x2={x + lineLength * 2 + offset}
                    y1={y + lineLength * 1.5 + offset}
                    y2={y + lineLength * 1.5 + offset}
                    stroke="white"
                    strokeWidth={4 / window.devicePixelRatio}
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                  />
                  <line
                    key="plus-verticle-white"
                    x1={x + lineLength * 1.5 + offset}
                    x2={x + lineLength * 1.5 + offset}
                    y1={y + lineLength + offset}
                    y2={y + lineLength * 2 + offset}
                    stroke="white"
                    strokeWidth={4 / window.devicePixelRatio}
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                  />
                  <line
                    key="plus-horizontal-black"
                    x1={x + lineLength + offset}
                    x2={x + lineLength * 2 + offset}
                    y1={y + lineLength * 1.5 + offset}
                    y2={y + lineLength * 1.5 + offset}
                    stroke="black"
                    strokeWidth={2 / window.devicePixelRatio}
                    vectorEffect="non-scaling-stroke"
                  />
                  <line
                    key="plus-verticle-black"
                    x1={x + lineLength * 1.5 + offset}
                    x2={x + lineLength * 1.5 + offset}
                    y1={y + lineLength + offset}
                    y2={y + lineLength * 2 + offset}
                    stroke="black"
                    strokeWidth={2 / window.devicePixelRatio}
                    vectorEffect="non-scaling-stroke"
                  />
                </>
              ));
            }
          } else if (altRef.current){
            setCursorPath((
              <>
                <line
                  key="minus-white"
                  x1={x + lineLength + offset}
                  x2={x + lineLength * 2 + offset}
                  y1={y + lineLength * 1.5 + offset}
                  y2={y + lineLength * 1.5 + offset}
                  stroke="white"
                  strokeWidth={4 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                  strokeLinecap="round"  
                />
                <line
                  key="minus-black"
                  x1={x + lineLength + offset}
                  x2={x + lineLength * 2 + offset}
                  y1={y + lineLength * 1.5 + offset}
                  y2={y + lineLength * 1.5 + offset}
                  stroke="black"
                  strokeWidth={2 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                />
              </>
            ));
          } else {
            setCursorPath((
              <>
                <rect
                  key="all-white"
                  x={x + lineLength + offset}
                  y={y + lineLength + offset}
                  width={lineLength}
                  height={lineLength}
                  stroke="white"
                  fill="transparent" 
                  strokeWidth={4 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                  strokeLinecap="round"
                />
                <rect
                  key="all-black"
                  x={x + lineLength + offset}
                  y={y + lineLength + offset}
                  width={lineLength}
                  height={lineLength}
                  stroke="black"
                  fill="transparent" 
                  strokeWidth={2 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                />
              </>
            ));
          }
        }
      }

      setDragPosition({ x: e.clientX, y: e.clientY });

      if (draggingSwatch !== null) {
        setMoveLeft(0);

        palette.forEach((_, index) => {
          const swatchRect = swatches.current[index].getBoundingClientRect();
          if (
            index !== draggingSwatch.swatch &&
            e.clientY > swatchRect.top - 8 &&
            e.clientY < swatchRect.bottom + 8 &&
            e.clientX > swatchRect.left - 64
          ) {
            if(e.clientX < swatchRect.left + 32){
              setMoveLeft(index);
            } else {
              setMoveLeft(index+1);
            }
          }
        });

        const alphaRect = alphaRef.current.getBoundingClientRect();
        if (
          e.clientY > alphaRect.top - 8 &&
          e.clientY < alphaRect.bottom + 8 &&
          e.clientX > alphaRect.left - 64 &&
          e.clientX < alphaRect.right
        ) {
          setMoveLeft(32);
        }

        // Handle first slot
        if (
          draggingSwatch.swatch !== 0 &&
          e.clientY < swatches.current[0].getBoundingClientRect().top
        ) {
          setMoveLeft(0);
        }

        // Handle last slot
        const lastIndex = palette.length - 1;
        if (
          draggingSwatch.swatch !== lastIndex &&
          e.clientY > swatches.current[lastIndex].getBoundingClientRect().bottom
        ) {
          setMoveLeft(lastIndex + 1);
        }

        // Start auto-scrolling
        if (!scrollFrameRef.current) {
          scrollFrameRef.current = requestAnimationFrame(handleAutoScroll);
        }
      }else if (clickSwatch && startPosition.current && distance(startPosition.current, { x: e.clientX, y: e.clientY }) > 10){
        setMoveLeft(clickSwatch.swatch);
        setDraggingSwatch(clickSwatch);
        setPreventClick(true);
        setClickSwatch(null);
      }
    };

    const handlePointerUp = (e) => {
      // touches.current = touches.current.filter(touch => touch.id !== e.pointerId);
      // if (scrolling.current) {
      //   if (touches.current.length === 0) scrolling.current = false;
      //   return;
      // }

      setDraggingSwatch(null);
      setClickSwatch(null);

      if (!draggingSwatch) return;

      if (moveLeft !== null && moveLeft !== draggingSwatch.swatch) {
        reorderSwatches(draggingSwatch.swatch, moveLeft);
      }

      setMoveLeft(null);
      if(paletteWrapperInnerRef.current) paletteWrapperInnerRef.current.style.overflowY = 'auto';
      
      if (dragInitTimer.current) {
        clearTimeout(dragInitTimer.current);
      } 

      // Stop auto-scrolling
      if (scrollFrameRef.current) {
        cancelAnimationFrame(scrollFrameRef.current);
        scrollFrameRef.current = null;
      }
    };

    const stopContext = (e) => {
      e.preventDefault();
      e.stopPropagation();
      return false;
    };

    // Attach event listeners
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handlePointerUp);
    window.addEventListener("pointermove", handlePointerMove);
    window.addEventListener("pointerup", handlePointerUp);
    window.addEventListener("contextmenu", stopContext);
    return () => {
      // Detach event listeners
      window.removeEventListener("touchend", handlePointerUp);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("pointermove", handlePointerMove);
      window.removeEventListener("pointerup", handlePointerUp);
      window.removeEventListener("contextmenu", stopContext);

      // Clean up auto-scrolling
      if (scrollFrameRef.current) {
        cancelAnimationFrame(scrollFrameRef.current);
        scrollFrameRef.current = null;
      }
    };
  }, [draggingSwatch, clickSwatch, moveLeft, shiftKey, altKey, shiftButton, altButton, metaButton]);

  const swatchClass = (swatch) => {
    let classList = "swatch";
    if (selectedSwatch === swatch) classList += " selected";
    if (draggingSwatch && draggingSwatch.swatch === swatch) classList += " moving";
    return classList;
  };

  const swatchBackground = (swatch) => {
    if(palette[swatch]){
      return { background: palette[swatch] }
    } else {
      return { background: `url(${blankSwatch})` }
    }
  };

  const handleCanvasOut = (e) => {
    corsurCoordinate.current = null;
    setCursorPath(null);
  }

  const handleCanvasMove = (e) => {
    const rect = paletteWrapperRef.current.getBoundingClientRect();
    corsurCoordinate.current = {x: e.clientX - rect.left, y: e.clientY - rect.top };
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if(e.keyCode === 18 || altButton) altRef.current = true;
      if (e.keyCode === 16 || shiftButton) shiftRef.current = true;
      if(e.ctrlKey || e.metaKey || metaButton) metaRef.current = true;

      if(!corsurCoordinate.current) return setCursorPath(null);
      const offset = 8;
      const lineLength = 8;
      const x = corsurCoordinate.current.x;
      const y = corsurCoordinate.current.y;

      if(metaRef.current){
        if(shiftRef.current){
          if(altRef.current){
            setCursorPath((
              <>
                <line
                  key="plus-diag1-white"
                  x1={x + lineLength + offset}
                  x2={x + lineLength * 2 + offset}
                  y1={y + lineLength * 2 + offset}
                  y2={y + lineLength + offset}
                  stroke="white"
                  strokeWidth={4 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke" 
                  strokeLinecap="round"
                />
                <line
                  key="plus-diag2-white"
                  x1={x + lineLength + offset}
                  x2={x + lineLength * 2 + offset}
                  y1={y + lineLength + offset}
                  y2={y + lineLength * 2 + offset}
                  stroke="white"
                  strokeWidth={4 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                  strokeLinecap="round"
                />
                <line
                  key="plus-diag1-black"
                  x1={x + lineLength + offset}
                  x2={x + lineLength * 2 + offset}
                  y1={y + lineLength * 2 + offset}
                  y2={y + lineLength + offset}
                  stroke="black"
                  strokeWidth={2 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                />
                <line
                  key="plus-diag2-black"
                  x1={x + lineLength + offset}
                  x2={x + lineLength * 2 + offset}
                  y1={y + lineLength + offset}
                  y2={y + lineLength * 2 + offset}
                  stroke="black"
                  strokeWidth={2 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                />
              </>
            ));
          } else{
            setCursorPath((
              <>
                <line
                  key="plus-horizontal-white"
                  x1={x + lineLength + offset}
                  x2={x + lineLength * 2 + offset}
                  y1={y + lineLength * 1.5 + offset}
                  y2={y + lineLength * 1.5 + offset}
                  stroke="white"
                  strokeWidth={4 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                  strokeLinecap="round"
                />
                <line
                  key="plus-verticle-white"
                  x1={x + lineLength * 1.5 + offset}
                  x2={x + lineLength * 1.5 + offset}
                  y1={y + lineLength + offset}
                  y2={y + lineLength * 2 + offset}
                  stroke="white"
                  strokeWidth={4 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                  strokeLinecap="round"
                />
                <line
                  key="plus-horizontal-black"
                  x1={x + lineLength + offset}
                  x2={x + lineLength * 2 + offset}
                  y1={y + lineLength * 1.5 + offset}
                  y2={y + lineLength * 1.5 + offset}
                  stroke="black"
                  strokeWidth={2 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                />
                <line
                  key="plus-verticle-black"
                  x1={x + lineLength * 1.5 + offset}
                  x2={x + lineLength * 1.5 + offset}
                  y1={y + lineLength + offset}
                  y2={y + lineLength * 2 + offset}
                  stroke="black"
                  strokeWidth={2 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                />
              </>
            ));
          }
        } else if (altRef.current){
          setCursorPath((
            <>
              <line
                key="minus-white"
                x1={x + lineLength + offset}
                x2={x + lineLength * 2 + offset}
                y1={y + lineLength * 1.5 + offset}
                y2={y + lineLength * 1.5 + offset}
                stroke="white"
                strokeWidth={4 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"  
              />
              <line
                key="minus-black"
                x1={x + lineLength + offset}
                x2={x + lineLength * 2 + offset}
                y1={y + lineLength * 1.5 + offset}
                y2={y + lineLength * 1.5 + offset}
                stroke="black"
                strokeWidth={2 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
              />
            </>
          ));
        } else {
          setCursorPath((
            <>
              <rect
                key="all-white"
                x={x + lineLength + offset}
                y={y + lineLength + offset}
                width={lineLength}
                height={lineLength}
                stroke="white"
                fill="transparent" 
                strokeWidth={4 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
              />
              <rect
                key="all-black"
                x={x + lineLength + offset}
                y={y + lineLength + offset}
                width={lineLength}
                height={lineLength}
                stroke="black"
                fill="transparent" 
                strokeWidth={2 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
              />
            </>
          ));
        }
      }
    };

    const handleKeyUp = (e) => {
      if(e.keyCode === 18 && !altButton) altRef.current = false;
      if (e.keyCode === 16 && !shiftButton) shiftRef.current = false;
      if(!e.ctrlKey && !e.metaKey && !metaButton) metaRef.current = false;

      if(!corsurCoordinate.current) return setCursorPath(null);
      const offset = 8;
      const lineLength = 8;
      const x = corsurCoordinate.current.x;
      const y = corsurCoordinate.current.y;

      if(!metaKey.current){
        setCursorPath(null);
      } else if(shiftRef.current){
        if(altRef.current){
          setCursorPath((
            <>
              <line
                key="plus-diag1-white"
                x1={x + lineLength + offset}
                x2={x + lineLength * 2 + offset}
                y1={y + lineLength * 2 + offset}
                y2={y + lineLength + offset}
                stroke="white"
                strokeWidth={4 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
              />
              <line
                key="plus-diag2-white"
                x1={x + lineLength + offset}
                x2={x + lineLength * 2 + offset}
                y1={y + lineLength + offset}
                y2={y + lineLength * 2 + offset}
                stroke="white"
                strokeWidth={4 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
              />
              <line
                key="plus-diag1-black"
                x1={x + lineLength + offset}
                x2={x + lineLength * 2 + offset}
                y1={y + lineLength * 2 + offset}
                y2={y + lineLength + offset}
                stroke="black"
                strokeWidth={2 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
              />
              <line
                key="plus-diag2-black"
                x1={x + lineLength + offset}
                x2={x + lineLength * 2 + offset}
                y1={y + lineLength + offset}
                y2={y + lineLength * 2 + offset}
                stroke="black"
                strokeWidth={2 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
              />
            </>
          ));
        } else{
          setCursorPath((
            <>
              <line
                key="plus-horizontal-white"
                x1={x + lineLength + offset}
                x2={x + lineLength * 2 + offset}
                y1={y + lineLength * 1.5 + offset}
                y2={y + lineLength * 1.5 + offset}
                stroke="white"
                strokeWidth={4 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
              />
              <line
                key="plus-verticle-white"
                x1={x + lineLength * 1.5 + offset}
                x2={x + lineLength * 1.5 + offset}
                y1={y + lineLength + offset}
                y2={y + lineLength * 2 + offset}
                stroke="white"
                strokeWidth={4 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
              />
              <line
                key="plus-horizontal-black"
                x1={x + lineLength + offset}
                x2={x + lineLength * 2 + offset}
                y1={y + lineLength * 1.5 + offset}
                y2={y + lineLength * 1.5 + offset}
                stroke="black"
                strokeWidth={2 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
              />
              <line
                key="plus-verticle-black"
                x1={x + lineLength * 1.5 + offset}
                x2={x + lineLength * 1.5 + offset}
                y1={y + lineLength + offset}
                y2={y + lineLength * 2 + offset}
                stroke="black"
                strokeWidth={2 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
              />
            </>
          ));
        }
      } else if (altRef.current){
        setCursorPath((
          <>
            <line
              key="minus-white"
              x1={x + lineLength + offset}
              x2={x + lineLength * 2 + offset}
              y1={y + lineLength * 1.5 + offset}
              y2={y + lineLength * 1.5 + offset}
              stroke="white"
              strokeWidth={4 / window.devicePixelRatio}
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
            />
            <line
              key="minus-black"
              x1={x + lineLength + offset}
              x2={x + lineLength * 2 + offset}
              y1={y + lineLength * 1.5 + offset}
              y2={y + lineLength * 1.5 + offset}
              stroke="black"
              strokeWidth={2 / window.devicePixelRatio}
              vectorEffect="non-scaling-stroke"
            />
          </>
        ));
      } else {
        setCursorPath((
          <>
            <rect
              key="all-white"
              x={x + lineLength + offset}
              y={y + lineLength + offset}
              width={lineLength}
              height={lineLength}
              stroke="white"
              fill="transparent" 
              strokeWidth={4 / window.devicePixelRatio}
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
            />
            <rect
              key="all-black"
              x={x + lineLength + offset}
              y={y + lineLength + offset}
              width={lineLength}
              height={lineLength}
              stroke="black"
              fill="transparent" 
              strokeWidth={2 / window.devicePixelRatio}
              vectorEffect="non-scaling-stroke"
            />
          </>
        ));
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <div 
        className="palette-wrapper-outer"
        ref={paletteWrapperRef}
        onPointerMove={handleCanvasMove}
        onPointerOut={handleCanvasOut}
    >
      <div className="palette-wrapper-inner" ref={paletteWrapperInnerRef}>
        {palette && palette.map((color, index) => (
          <React.Fragment key={index}>
            <div className="move-space" style={{ display: moveLeft === index ? "block" : "none" }}></div>
            <div
              key={index}
              onPointerDown={(e) => handleSwatchPointerDown(e, color, index)}
              onPointerUp={(e) => handleSwatchChange(e, index)}
            >
              <div 
                ref={el => swatches.current[index] = el}
                className={swatchClass(index)}
                style={swatchBackground(index)}
              ></div>
            </div>
          </React.Fragment>
        ))}
        <>
          <div className="move-space" style={{ display: moveLeft === 32 ? "block" : "none" }}></div>
          <div
            key={32}
            ref={alphaRef}
            className={alphaClass()}
            onClick={(e) => handleSwatchChange(e, 32)}
          ></div>
        </>
        {draggingSwatch && <div
          ref={floatingSwatch}
          className="swatch floating-swatch"
          style={{
            left: `${dragPosition.x - 20}px`,
            top: `${dragPosition.y - 20}px`,
            background: draggingSwatch.color || `url(${blankSwatch})`
          }}
        ></div>}
      </div>
      <svg className="palette-svg" width={svgWidth} height={svgHeight}>
        <g>{cursorPath}</g>
      </svg>
    </div>
  );
}

export default Palette;
