import React from "react";
import "../css/Panel.css";
import TopLeftWindow from "./TopLeftWindow";
import BottomLeftWindow from "./BottomLeftWindow";

function LeftPanel() {
  return (
    <div className="left-panel">
      <TopLeftWindow />
      <BottomLeftWindow />
    </div>
  );
}

export default LeftPanel;
