import React, { useState, useRef, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { apiUrl, fillerPalette, maxResolution} from "../constants";
import axios from 'axios';
import chroma from "chroma-js";
import RgbQuant from 'rgbquant';
import "../css/Import.css";
import { onlyIntInput} from "../utils/basics";
import { useMainContext } from "../contexts/MainContext";

function Import({ palette, onCreate}) {
    const { resolution } = useMainContext();

    const navigate = useNavigate();

    const canvas = useRef(null);
    const input = useRef(null);

    const [image, setImage] = useState(null);

    const [name, setName] = useState('Untitled');
    const [aspectRatio, setAspectRatio] = useState(1);
    const [width, setWidth] = useState(64);
    const [height, setHeight] = useState(64);
    const [maxWidth, setMaxWidth] = useState(maxResolution);
    const [maxHeight, setMaxHeight] = useState(maxResolution);
    const [canvasStyle, setCanvasStyle] = useState({ display: "none"});
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoadig, setIsLoading] = useState(false);
    
    // Helper function to store document ID in local storage
    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!name) return setErrorMessage("Document name is required.");
        if(name.length > 32) return setErrorMessage("Document name must be 32 or less characters.");
        if(!/^[A-Za-z0-9_ -]+$/.test(name)) return setErrorMessage("Document name contains invalid characters.");
        
        onCreate(canvas.current, name);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    function handleUpload(e) {
        setImage(null);
        setIsLoading(true);
        setErrorMessage(null);
        setCanvasStyle({ display: "none" });

        //set name
        const filename = e.target.files[0].name;
        const extension = filename.split('.').pop();
        if(extension !== "jpg" && extension !== "jpeg" && extension !== "png" && extension !== "gif" && extension !== "webp"){
            setIsLoading(false);
            return setErrorMessage("Image must be a JPG, PNG, GIF, or WEBP.");
        }
        let docName = filename.substr(0, filename.lastIndexOf('.')) || filename;
        docName = docName.replace(/[^a-zA-Z0-9 _-]/g, '').trim();
        docName = docName.slice(0, 32);
        setName(docName);

        //upload file
        var img = new Image();
        img.onload = draw;
        img.onerror = failed;
        img.src = URL.createObjectURL(e.target.files[0]);
    };

    const handleNewBrowse = (e) => {
        e.preventDefault();
        input.current.click();
    };

    function draw(){
        setImage(this);

        //constrain image to max resolution
        const tempAspectRatio = this.width/this.height;
        let tempWidth = this.width;
        let tempHeight = this.height;
        if(tempAspectRatio > 1){
            setCanvasStyle({ width: "100%", height: "auto"});
            if(tempWidth > resolution.width){
                tempWidth = resolution.width;
                tempHeight = Math.round(resolution.width / tempAspectRatio);
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(maxResolution);
                setMaxHeight(Math.round(maxResolution / tempAspectRatio));
            } else {
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(maxResolution);
                setMaxHeight(Math.round(maxResolution / tempAspectRatio));
            }
        } else{
            setCanvasStyle({ width: "auto", height: "100%"});
            if(tempHeight > resolution.height){
                tempWidth = Math.round(resolution.height * tempAspectRatio);
                tempHeight = resolution.height;
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(Math.round(maxResolution * tempAspectRatio));
                setMaxHeight(maxResolution);
            } else {
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(Math.round(maxResolution * tempAspectRatio));
                setMaxHeight(maxResolution);
            }
        }
        setAspectRatio(tempAspectRatio);

        //get and draw canvas
        canvas.current.width = tempWidth > 0 ? tempWidth : 1;
        canvas.current.height = tempHeight > 0 ? tempHeight : 1;
        const ctx = canvas.current.getContext("2d", { willReadFrequently: true });
        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(this, 0, 0, canvas.current.width, canvas.current.height);

        //set transparency to full transparent
        const imageData = ctx.getImageData( 0, 0, canvas.current.width, canvas.current.height);
        let hasColor;
        for (let j = 0; j < imageData.data.length; j += 4) {
            if(imageData.data[j + 3] > 0) {
                imageData.data[j + 3] = 255;
                hasColor = true;
            }
        }
        ctx.putImageData(imageData, 0, 0);

        if(!hasColor){
            setImage(null);
            setIsLoading(false);
            setCanvasStyle({ display: "none" });
            return setErrorMessage("Image must have opaque pixels.");
        }

        //quantize image    
        const rgbPalette = [];
        for(const color of palette){
            if(color) rgbPalette.push(chroma(color).rgb());
        }
        const q = new RgbQuant({colors: rgbPalette.length, palette: rgbPalette});
        q.sample(canvas.current);
        const u8  = q.reduce(canvas.current, true);
        const clamped = new Uint8ClampedArray(u8);
        const imgData2 = new ImageData(clamped, canvas.current.width, canvas.current.height);
        ctx.putImageData(imgData2,0,0);

        setIsLoading(false);

        return setErrorMessage(null);
    }

    function failed(){
        return setErrorMessage("Error loading image.");
    }

    const handleWidthChange = useCallback((e) => {
        //constrain image to max resolution
        let tempWidth = e.target.value;
        let tempHeight = Math.round(tempWidth / aspectRatio);
        if(aspectRatio > 1){
            if(tempWidth > maxResolution){
                tempWidth = maxResolution;
                tempHeight = Math.round(maxResolution / aspectRatio);
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(tempWidth);
                setMaxHeight(tempHeight);
            } else {
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(maxResolution);
                setMaxHeight(Math.round(maxResolution / aspectRatio));
            }
        } else{
            if(tempHeight > maxResolution){
                tempWidth = Math.round(maxResolution * aspectRatio);
                tempHeight = maxResolution;
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(tempWidth);
                setMaxHeight(tempHeight);
            } else {
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(Math.round(maxResolution * aspectRatio));
                setMaxHeight(maxResolution);
            }
        }

        //get and draw canvas
        canvas.current.width = tempWidth > 0 ? tempWidth : 1;
        canvas.current.height = tempHeight > 0 ? tempHeight : 1;
        const ctx = canvas.current.getContext("2d", { willReadFrequently: true });
        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(image, 0, 0, canvas.current.width, canvas.current.height);

        //set transparency to full transparent
        const imageData = ctx.getImageData( 0, 0, canvas.current.width, canvas.current.height);
        for (let j = 0; j < imageData.data.length; j += 4) {
            if(imageData.data[j + 3] < 255) {
                imageData.data[j + 3] = 0;
            }
        }
        ctx.putImageData(imageData, 0, 0);

        //quantize image        
        const rgbPalette = [];
        for(const color of palette){
            if(color) rgbPalette.push(chroma(color).rgb());
        }
        const q = new RgbQuant({colors: rgbPalette.length, palette: rgbPalette});
        q.sample(canvas.current);
        const u8  = q.reduce(canvas.current, true);
        const clamped = new Uint8ClampedArray(u8);
        const imgData2 = new ImageData(clamped, canvas.current.width, canvas.current.height);
        ctx.putImageData(imgData2,0,0);
    }, [aspectRatio, image]);

    const handleHeightChange = useCallback((e) => {
        //constrain image to max resolution
        let tempHeight = e.target.value;
        let tempWidth = Math.round(tempHeight * aspectRatio);
        if(aspectRatio > 1){
            if(tempWidth > maxResolution){
                tempWidth = maxResolution;
                tempHeight = Math.round(maxResolution / aspectRatio);
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(tempWidth);
                setMaxHeight(tempHeight);
            } else {
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(maxResolution);
                setMaxHeight(Math.round(maxResolution / aspectRatio));
            }
        } else{
            if(tempHeight > maxResolution){
                tempWidth = Math.round(maxResolution * aspectRatio);
                tempHeight = maxResolution;
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(tempWidth);
                setMaxHeight(tempHeight);
            } else {
                setWidth(tempWidth);
                setHeight(tempHeight);
                setMaxWidth(Math.round(maxResolution * aspectRatio));
                setMaxHeight(maxResolution);
            }
        }

        //get and draw canvas
        canvas.current.width = tempWidth > 0 ? tempWidth : 1;
        canvas.current.height = tempHeight > 0 ? tempHeight : 1;
        const ctx = canvas.current.getContext("2d", { willReadFrequently: true });
        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(image, 0, 0, canvas.current.width, canvas.current.height);

        //set transparency to full transparent
        const imageData = ctx.getImageData( 0, 0, canvas.current.width, canvas.current.height);
        for (let j = 0; j < imageData.data.length; j += 4) {
            if(imageData.data[j + 3] < 255) {
                imageData.data[j + 3] = 0;
            }
        }
        ctx.putImageData(imageData, 0, 0);

        //quantize image    
        const rgbPalette = [];
        for(const color of palette){
            if(color) rgbPalette.push(chroma(color).rgb());
        }
        const q = new RgbQuant({colors: rgbPalette.length, palette: rgbPalette});
        q.sample(canvas.current);
        const u8  = q.reduce(canvas.current, true);
        const clamped = new Uint8ClampedArray(u8);
        const imgData2 = new ImageData(clamped, canvas.current.width, canvas.current.height);
        ctx.putImageData(imgData2,0,0);
    }, [aspectRatio, image]);

    return (
        <div className="import-wrapper">
            <h2 className="import-heading">Import Image</h2>
            {errorMessage && <div className="import-error">{errorMessage}</div>}
            <div className="import-canvas-wrapper" style={canvasStyle}>
                <canvas className="import-canvas" ref={canvas}></canvas>
            </div>
            {isLoadig && 
                <>
                    <div>Quantizing image. Please wait.</div>
                    <div className="import-loading-wrapper">
                        <span className="icon-spinner spinner"></span>
                    </div>
                </>
            }
            {image && !isLoadig ?
                <>
                    <label className="import-label" htmlFor="name">Layer Name:</label>
                    <input id="name" className="import-input-name" value={name} onChange={handleNameChange}/>
                    <label className="import-label" htmlFor="width">Width:</label>
                    <div className="import-input-wrapper">
                        <input
                            id="width"
                            type="number"
                            className="import-size"
                            value={width}
                            onKeyDown={onlyIntInput}
                            onChange={handleWidthChange}
                            max={maxWidth}
                        /> Max: {maxWidth}px
                    </div>
                    <label className="import-label" htmlFor="height">Height:</label>
                    <div className="import-input-wrapper">
                        <input
                            id="height"
                            type="number"
                            className="import-size"
                            value={height}
                            onKeyDown={onlyIntInput}
                            onChange={handleHeightChange}
                            max={maxHeight}
                        /> Max: {maxHeight}px
                    </div>
                </>
                :
                <>
                    <input type="file" onChange={handleUpload} ref={input} style={{display: 'none'}} />
                    <button className="import-browse" onClick={() => input.current.click()}>Browse for an image</button>
                </>
            }
            {image && !isLoadig && <>
                <div className="import-bottom">
                    <input type="file" onChange={handleUpload} ref={input} style={{display: 'none'}} />
                    <input type="submit" value="Browse" className="import-submit" onClick={handleNewBrowse}/>
                    <input type="submit" value="Open" className="import-submit" onClick={handleSubmit}/>
                </div>
            </>}
        </div>
    );
}

export default Import;
